.EventReportFilter_Wrapper {
  border: solid 1px black;
  padding: 20px;
  margin-bottom: 30px;
}

.EventReportFilter_DateInputWrapper {
  display: inline;
}
.EventReportFilter_DateFilterWrapper {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  align-items: flex-end;
}