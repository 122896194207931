@import "../../styles/common.scss";

$primaryDark: #0177C0;

.club-form {
    width: 650px;
    margin: 0 auto;
    padding: 20px 20px 60px;

    &__title {
        font-size: 20px;
        font-weight: bold;
    }

    &__error-message {
        color: red;
    }

    &__field-wrapper {
        display: flex;
        justify-content: space-between;

        .club-form__select {
            width: 47%;
        }

        .club-form__field {
            width: 47% !important;
        }
    }

    &__field {
        width: 100%;
        margin-bottom: 20px !important;

        &.short {
            width: auto;
        }

        &_error {
            font-weight: 700 !important;
            color: #D32F2F !important;
            font-size: 14px !important;
        }
    }

    &__description {
        color: #555;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 30px;
    }

    &__select {
        width: 100%;
        margin-bottom: 20px !important;
    }

    .subtitle {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 15px;
        margin-top: 30px;
    }

    &__checkbox {
        padding: 0 !important;
    }

    &__button-wrapper {
        display: flex;
        justify-content: center;
    }

    &__button {
        background-color: $primaryDark !important;
        color: #fff !important;
    }

    &__button-label {
        font-size: 16px;
        font-weight: 800;
    }

    &__button-disabled {
        background-color: $disabled !important;
    }

    .radio-wrapper {
        display: flex;

        .radio-item {
            margin-right: 35px;
        }
    }
}
