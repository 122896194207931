$disabled: rgba(185, 185, 185, 1);
$primaryDark: #0177C0;

.ClubMemberManagement_Form {
  width: 650px;
  margin: 0 auto;
  padding: 20px 20px 60px;

  &__title {
    font-size: 20px;
    font-weight: bold;
  }

  &__message {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 30px;
    &.errored {
      color: red;
    }
  }

  .subtitle {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 30px;
  }

  &__field-wrapper {
    display: flex;
    justify-content: space-between;

    .ClubMemberManagement_Form__select {
      width: 47%;
    }

    .ClubMemberManagement_Form__field {
      width: 47% !important;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  &__button {
    background-color: $primaryDark !important;
    color: #fff !important;
  }

  &__button-label {
    font-size: 16px;
    font-weight: 800;
  }

  &__button-disabled {
    background-color: $disabled !important;
  }

  .MuiAutocomplete-endAdornment Button {
    height: inherit;
    font-size: inherit;
    min-width: inherit;
  }
}
