@import "../../styles/common.scss";

.ManagePassionsList {
  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
  }

  tr:nth-of-type(2n) > td {
    background-color: $callout;
  }

  td {
    padding: 15px;
  }

  form {
    display: flex;
    flex-direction: row;
    align-items: center;

    >:first-child {
      flex-grow: 1;
    }
  }

  .ManagePassionRow_UpdateButton {
    position: relative;
  }

  .ManagePassionRow_UpdateProgress {
    position: absolute;
    left: 50%;
    margin-left: -1em;

    &.Updating {
      visibility: visible;
    }

    &.NotUpdating {
      visibility: hidden;
    }
  }
}
