@import "../../styles/common.scss";

.Header {

  display: flex;
  padding-top: 2em;
  padding-bottom: 2em;
  justify-content: flex-start;
  width: 100%;

  div {
    display: flex;
    width: 300px;
  }

  .Logo {
    width: 200px;
  }

  .Title {
    margin-left: 50px;
    font-size: 36px;
    align-self: center;
  }
}
