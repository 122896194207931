.MasqueradeForm {
  input {
    margin-left: 1em;
  }

  pre {
    white-space: pre-wrap;
  }
  .MasqueradeForm_ResultWrapper {
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
  }
}
