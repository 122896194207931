@import "../../styles/common.scss";




.Layout {
  width: 1024px;
  padding-left: 12px;
  padding-right: 12px;
}
