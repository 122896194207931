@import "../../styles/common.scss";

.Footer {

  padding-top: 2em;
  padding-bottom: 2em;

  .Logo {
    width: 200px;
  }
}
