.Paging_Page {
  padding: 15px;
  border: 1px solid black;
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
  &.selected {
    background-color: black;
    color: white;
  }
}
