@import "../../styles/common.scss";

.ManageUserList_TableWrapper {
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

  .Disabled {
    color: $darkErrored;
  }
  .Enabled {
    color: $success;
  }
}
.ManageUserList_GridItem {
  display: inline-flex;
  align-items: center;
  padding: 15px;
}
.ManageUserList_UserType {
  display: block;
}
.ManageUserList_AccountStatus button {
  margin-left: 5px;
}
.ManageUserList_Row {
  display: contents;
}
.ManageUserList_Row:nth-of-type(2n) > .ManageUserList_GridItem {
  background-color: $callout;
}
.ManageUserList_Message {
  padding-top: 10px;
  padding-bottom: 10px;
}
.ManageUserList_Headers > .ManageUserList_GridItem {
  font-weight: bold;
  font-size: 20px;
}
