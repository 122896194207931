.UserReportFilter_Wrapper {
  border: solid 1px black;
  padding: 20px;
  margin-bottom: 30px;
}

.UserReportFilter_DateInputWrapper {
  display: inline;
}
.UserReportFilter_DateFilterWrapper {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  align-items: flex-end;
}
.UserReportFilter_SalesforceCheckboxWrapper {
  display: flex;
  .MuiFormControl-root {
    flex-direction: row;
    align-items: center;
  }
}
