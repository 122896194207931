@import "../../styles/common.scss";

.LoginForm {
  margin: 1em;

  input {
    margin-left: 1em;
  }

  .Grid {
    display: grid;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-gap: 5px 5px;
    width: 50%;
  }

  .EmailLabel {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }
  .Email {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }
  .PasswordLabel {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
  }
  .Password {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;
  }
  .ButtonAndResult {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
  }
  .LoginResult {
    color: $errored;
  }
}
