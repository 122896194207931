@import "../../styles/common.scss";

.ViewEditTextField_Wrapper {
  white-space: nowrap;

  .ViewEditTextField_Input {
    width: 350px;
    margin: 9px 0;
  }
}

