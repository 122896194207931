@import "../../styles/common.scss";

.ManageClubsList_TableWrapper {
  width: calc(100% + 80px);
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 3fr 1fr 1fr 2fr 1fr 1fr;

  .Inactive {
    color: $darkErrored;
  }
  .Active {
    color: $success;
  }
}

.ManageClubsList_FiltersWrapper {
  display: flex;
}

.ManageClubsList_FormControlWrapper {
  display: flex;
  margin-right: 8px;
  .MuiFormControl-root {
    flex-direction: row;
    align-items: center;
  }
}

.ManageClubsList_ShortCodes {
  .MuiFormControl-root {
    display: flex;
    justify-content: flex-start;

    .MuiCheckbox-root {
      padding: 2px;
    }

    > label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 90px;
    }
  }

  .ViewEditTextField_Wrapper {
    width: 300px;
    display: flex;
  }

  > span {
    color: #f50057;
  }
}

.ManageClubsList_AddShortCodeButton {
  position: relative;
  height: 40px;
  margin-top: 12px;

  .ViewEditTextField_Wrapper {
    position: absolute;
    background-color: #ebebeb;
    z-index: 100;
    border-radius: 4px;
  }

  > p {
    color: #0091e9;
    cursor: pointer;
    margin: 0;
    padding-top: 4px;
  }
}

.ManageClubsList_GridItem {
  display: inline-flex;
  align-items: center;
  padding: 15px;
}
.ManageClubsList_Row {
  display: contents;
}
.ManageClubsList_Row:nth-of-type(2n) > .ManageClubsList_GridItem {
  background-color: $callout;
}
.ManageClubsList_Message {
  padding-top: 10px;
  padding-bottom: 10px;
}
.ManageClubsList_Headers > .ManageClubsList_GridItem {
  font-weight: bold;
  font-size: 20px;
}

