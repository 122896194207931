@import "../../styles/common.scss";

.MyAccount {
  display: flex;
}

.MyAccount svg {
  display: flex;
  color: $primaryAccent;
  font-size: 3em ! important;
}

.MyAccount_Links {
  flex-direction: column;
  margin-left: 1em;
}

.MyAccount_Logout {
  margin-top: .1em;
  cursor: pointer;
  color: $primaryAccent;
  font-weight: $bold;
}

